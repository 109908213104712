@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  @apply scroll-smooth;
}

body {
  height: 100%;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 300ms ease;
}

.underline-animation,
.blog-content a {
  position: relative;
  text-decoration: none;
}

.underline-animation::after,
.blog-content a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
  transform: scaleX(0);
}

.underline-animation:hover::after,
.blog-content a:hover::after {
  transform: scaleX(1);
}

* {
  box-sizing: border-box;
}

.heading {
  font-family: "Raleway", sans-serif;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.banner {
  padding-top: 92px;
}

.btn-primary {
  @apply py-3 px-6 rounded-full transition-all duration-300;
}

.scrollbar::-webkit-scrollbar {
  @apply w-2;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 3rem;
  border: 2px solid white;
}

.scrollbar::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 0.4rem;
}

.scrollbar::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 0.4rem;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: white;
    color: black;
  }
}
